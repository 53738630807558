import "./style.css";
import logo from "../../assets/images/logo.png";
import water_pic_2 from "../../assets/images/Frame1.png";
import water_pic_4 from "../../assets/images/Frame2.png";
import water_pic_1 from "../../assets/images/Frame3.png";
import water_pic_3 from "../../assets/images/Frame4.png";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import config from "../../config";
import background_animation_right_1 from "../../assets/images/animation_1_right.png";
import background_animation_left_1 from "../../assets/images/animation_1_left.png";
import axios from "axios";


export default function Home() {
    let navigate = useNavigate();
    const [login, setLogin] = useState({
        name: "",
        email: "",
    })
    const [error, setError] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    const [display, setDisplay] = useState(false)
    let [sendData, setSendData] = useState("")
    const btnRef = useRef(null)

    const hide = (ms) => {
        setDisplay(true)
    }

    useEffect(() => {
        if (btnRef.current) {
            setTimeout(() => {
                btnRef.current.classList.add("talk_us_btn")
            }, 1000)
        }
    }, [btnRef])

    const handleChangeInput = (e, name) => {
        setLogin({...login, [name]: e})
        setError("")
        setSendData("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(login.email).toLowerCase());
    }
    const handleClickLogin = () => {
        console.log("send")
        let values = {
            name: login.name,
            email: login.email,
        }
        setLoginBtn(true)
        if (login.email.length && login.name.length) {
            if (login.name.length > 2) {
                if (validateEmail()) {
                    axios.post(`${config.url}/add-request`, values)
                        .then(response => {
                            console.log(response, "response");
                            localStorage.setItem('jwtToken', response.data.token)
                            // navigate("/dashboard")
                            setSendData(response.data.message)
                        })
                        .catch(error => {
                            console.log(error.response, "error")
                            setError(error.response?.data.error)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Name is incorrect")
            }
        }
    }

    return (
        <div>
            <div>
                <img src={background_animation_left_1} alt="animation" className="animation_left"/>
                <img src={background_animation_right_1} alt="animation" className="animation_right"/>
            </div>
            <div className="container">
                <div className="home_container">
                    <div className="d_flex fd_column justify_content_start logo_b">
                        <img src={logo} alt="logo" onClick={() => navigate("/")}/>
                        <p className="text_left">Saving The World`s Water</p>
                    </div>
                    <div className="d_grid grid_gab_20 grid_columns_4fr home_container_pic_b">
                        <img src={water_pic_1} alt="water_pic_1"/>
                        <img src={water_pic_2} alt="water_pic_1"/>
                        <img src={water_pic_3} alt="water_pic_1"/>
                        <img src={water_pic_4} alt="water_pic_1"/>
                    </div>
                    {
                        !display ?
                            <div className="home_container_text_b text_center fs_32 f_600 text_center">
                                We make tools to help tenants and property owners conserve our most precious resource
                            </div>
                            :
                            <div
                                className="home_container_text_b home_container_text_b_2 text_center fs_32 f_600 text_center">
                                We make tools to help tenants and property owners conserve our most precious resource
                            </div>
                    }
                    {!display ?
                        <button ref={btnRef} className="bc_blue button_full f_600 fs_24 xoski" onClick={hide}>Talk To
                            Us</button>
                        :
                        <div className="Modal  inputs_block">
                            <input type="text" placeholder="Name"
                                   style={!login.name && loginBtn ? {border: "1px solid #2A3E8E"} : null}
                                   onChange={(e) => handleChangeInput(e.target.value, 'name')}/>
                            <input type="text" placeholder="Email"
                                   style={!login.email && loginBtn ? {border: "1px solid #2A3E8E"} : null}
                                   onChange={(e) => handleChangeInput(e.target.value, 'email')}/>
                            {error ? <p className="error">{error}</p> : null}
                            <button ref={btnRef} className="bc_blue button_full f_600 fs_24 margin_top_32 send_btn"
                                    onClick={handleClickLogin}>Send
                            </button>
                            {sendData ? <p className="c_blue fs_18 f_500 send_data_text">{sendData}</p> : null}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}